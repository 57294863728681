import React from "react"
import Header from "../components/Header/Header"
import { graphql, Link } from "gatsby"
import Footer from "../components/Footer/Footer"
import BannerStatic from "../components/BannerStatic/NewsBanner"
import StaticBookValuation from "../components/StaticBookValuation/StaticBookValuation"
import NewsImageWithText from "../components/StaticImageWithText/NewsImageWithText"
import StaticRichTextBlock from "../components/StaticRichTextBlock/StaticRichTextBlock"
import NewsInnerImage from "../components/StaticImageWithText/NewsInnerImage"
import NewsSlider from "../components/NewsSlider/OtherNews"
import NewsletterBanner from "../components/FullWidthBanner/NewsletterBanner"
import "../components/StaticImageWithText/NewsDetails.scss"
import SEO from "../components/Seo/seo"

const NewsDetails = ({ data }) => {
  const blog = data.glstrapi.blog
  const modules = data.glstrapi.blog.modules
  return (
    <div className="news-details-page">
      <Header />
      <SEO
        title={blog.title}
        description={
          "Read about " +
          blog.title +
          " here and subscribe to our newsletter to stay up-to-date about everything going on at Om Home."
        }
      />
      <BannerStatic banner={blog} id={blog.id} slug={blog.slug} />
      <NewsImageWithText module={blog} />
      {modules.map((module, i) => {
        return (
          <>
            {module.__typename ===
              "GLSTRAPI_ComponentModulesDetailPageModules" &&
              module.select_module === "book_a_valuation" && (
                <StaticBookValuation module={module} />
              )}

            {module.__typename === "GLSTRAPI_ComponentBlocksImageBlock" && (
              <NewsInnerImage module={module} />
            )}
            {module.__typename === "GLSTRAPI_ComponentBlocksRichTextBlock" && (
              <StaticRichTextBlock module={module} />
            )}
          </>
        )
      })}
      <NewsSlider title={`May also interest you...`} />
      <NewsletterBanner />
      <Footer />
    </div>
  )
}

export default NewsDetails

export const pageQuery = graphql`
  query GetNewsPage($articleId: ID!) {
    glstrapi {
      blog(id: $articleId, publicationState: LIVE) {
        id
        seo {
          title
          description
        }
        title
        slug
        banner_image {
          url
          alternativeText
        }
        team {
          id
          seo_meta {
            title
            description
          }
          name
          designation
          slug
          email
          mobile_no
          content
          image {
            url
            url_sharp {
              id
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  height: 24
                  width: 24
                  layout: FIXED
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
        }
        post_on
        blogs_categories {
          category
          url
        }
        description
        modules {
          ... on GLSTRAPI_ComponentBlocksImageBlock {
            __typename
            id
            image {
              url
              alternativeText
            }
          }
          ... on GLSTRAPI_ComponentModulesDetailPageModules {
            __typename
            id
            select_module
          }

          ... on GLSTRAPI_ComponentBlocksRichTextBlock {
            __typename
            id
            content
          }
        }
      }
    }
  }
`
