import React from "react"
import "./StaticImageWithText.scss"
import parse from "html-react-parser"
import GetGatsbyImage from "../common/site/get-gatsby-image"

const NewsImageWithText = ({ module }) => {
  return (
    <div className="static-image-text-wrap static-container news-top-banner">
      {/* <GetGatsbyImage
        image={module.media_block.media_file}
        fallbackalt={module.text_block?.title || ""}
        className="static-img"
      /> */}
      <img src={module?.banner_image.url} className="static-img" />
      {/* <img
        src={module?.media_block?.media_file?.url}
        alt="image"
        className="static-img"
      /> */}
      <div className="description">
        {module?.description &&
          parse(module?.description)}
      </div>
    </div>
  )
}

export default NewsImageWithText
