import React from "react"
import { Container, Col, Row } from "react-bootstrap"
import { graphql, Link, useStaticQuery } from "gatsby"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./assets/styles/_index.scss"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import { NewsURL } from "../commonURLs"

const OtherNewsSlider = (props) => {
  const data = useStaticQuery(graphql`
    query GetOtherOtherNewsSliderData {
      glstrapi {
        blogs {
          id
          tile_image {
            url
            url_sharp {
              id
              childImageSharp {
                gatsbyImageData(
                  formats: WEBP
                  height: 328
                  width: 264
                  layout: FIXED
                  quality: 100
                  transformOptions: { cropFocus: CENTER, fit: COVER }
                )
              }
            }
          }
          title
          slug
          blogs_categories {
            category
            url
          }
        }
      }
    }
  `)

  const filterNews = news => {
    let hasCategory = false
    news.blogs_categories?.forEach(category => {
      if (category.url === "sales") hasCategory = true
    })
    return hasCategory
  }

  const allNews = data.glstrapi.blogs
  const filteredNews = allNews.filter(filterNews)

  // Slider settings
  let settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: false,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
          arrows: false,
          dots: true,
        },
      },
    ],
  }
  // Slider settings

  return (
    <section className="new-slider-wrapper section-p othernews">
      <div className="new-slider-slider-wrapper">
        <Container>
          <Row>
            <Col>
              <h2 className="title">{props?.title}</h2>
              <br></br>
              {/* <div className="d-flex justify-content-between align-items-center new-slider-header-wrapper">
                <Link to={NewsURL} className="view-more d-md-block d-none">
                  View More
                </Link>
              </div> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <Slider className="new-slider-slider" {...settings}>
                {filteredNews?.length > 0 &&
                  filteredNews.map((news, index) => {
                    return (
                      <>
                        <div className="new-slider-slide">
                          <div className="new-slider-img-zoom">
                            <Link to={NewsURL + news.slug + "/"}>
                              <GetGatsbyImage
                                image={news?.tile_image}
                                fallbackalt={news.title}
                                className="img-fluid"
                              />
                            </Link>
                          </div>
                          <div className="new-slider-text-wrapper">
                            <p className="news-cat">Sales</p>
                            <Link
                              to={NewsURL + news.slug + "/"}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <p className="link-text">{news.title}</p>
                            </Link>
                          </div>
                        </div>
                      </>
                    )
                  })}
              </Slider>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default OtherNewsSlider
