import React, { useEffect, useState } from "react"
import "./BannerStatic.scss"
import Breadcrumbs from "../BreadCrumbs/DetailsBreadCrumbs"
import {
  GetPageURL,
  PageBreadcrumbs,
  scrollToElement,
} from "../common/site/utils"
import parse from "html-react-parser"
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share"
import fbLogo from "../../images/icons/round-fb.svg"
import twiiterLogo from "../../images/icons/round-twitter.svg"
import linkedInLogo from "../../images/icons/round-in.svg"
import { useLocation } from "react-use"
import GetGatsbyImage from "../common/site/get-gatsby-image"
import format from "date-fns/format"

const BannerStatic = ({ banner, id, slug }) => {
  const urlState = useLocation()

  // const [scrolled, setScrolled] = useState(false)
  const shareURL = urlState?.href
  const host = urlState?.host

  // const handleScroll = () => {
  //   if (typeof window !== 'undefined') {
  //     if (window.pageYOffset > 420) {
  //       setScrolled(true)
  //     } else {
  //       setScrolled(false)
  //     }
  //   }
  // }

  // useEffect(() => {
  //   handleScroll()

  //   window.addEventListener("scroll", handleScroll)
  //   return () => window.removeEventListener("scroll", handleScroll)
  // }, [])

  return (
    <div className="static-banner-wrapper">
      <Breadcrumbs
        breadcrumbs={PageBreadcrumbs("dubai-property-news")}
        title={banner.title}
      />
      <div className="static-banner-container static-container news-banner-container">
        <h1 className="heading">{banner.title}</h1>
        <div className="content">
          <div className="team-section">
            <GetGatsbyImage
              image={banner.team.image}
              fallbackalt="team - image"
              className="new-team-img"
            />
            <p className="name">{banner.team.name}</p>
          </div>
          <div className="divider">/</div>
          <p className="date">
            {format(new Date(banner.post_on), "iii dd MMM yyyy")}
          </p>
          <div className="divider">/</div>
          <div className="catgories">
            {banner.blogs_categories?.length > 0 &&
              banner.blogs_categories.map((item, i) => (
                <p className="catgory">
                  <span>{item.category}</span>{" "}
                  {i !== banner.blogs_categories.length - 1 ? "," : ""}
                </p>
              ))}
          </div>
        </div>
        <div className="cta-section">
          <FacebookShareButton
            quote={banner.title}
            url={shareURL}
            className="fb-logo"
          >
            <img src={fbLogo} alt="fb-logo" />
          </FacebookShareButton>
          <TwitterShareButton
            title={banner.title}
            url={shareURL}
            className="twitter-logo"
          >
            <img src={twiiterLogo} alt="twiiter-logo" />
          </TwitterShareButton>
          <LinkedinShareButton
            title={banner.title}
            url={shareURL}
            source={host}
            className="linkedin-logo"
          >
            <img src={linkedInLogo} alt="linkedin-logo" />
          </LinkedinShareButton>
        </div>
      </div>
    </div>
  )
}

export default BannerStatic
