import React from "react"
import "./StaticImageWithText.scss"
import parse from "html-react-parser"
import GetGatsbyImage from "../common/site/get-gatsby-image"

const NewsInnerImage = ({ module }) => {
  return (
    <div className="static-image-text-wrap static-container">
      {/* <GetGatsbyImage
        image={module?.image}
        fallbackalt={module.text_block?.title || ""}
        className="static-img"
      /> */}
      <img src={module?.image.url} className="static-img" />
      {/* <img
        src={module?.media_block?.media_file?.url}
        alt="image"
        className="static-img"
      /> */}
      <div className="description">
        {module?.description &&
          parse(module?.description)}
      </div>
    </div>
  )
}

export default NewsInnerImage
